import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { Link as RouterLink, PageProps } from "gatsby";
import Share from "../../components/Share";
import bricks from "../../images/bricks.svg";
import reports from "../../images/research/r.svg";
import Arrow from "../../images/arrow-right.inline.svg";
import Read from "../../images/research/read.inline.svg";
import Read2 from "../../images/research/read2.inline.svg";
import researchData from "../../../data/research.json";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import slugify from "react-slugify";

const research = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData
        title="Research"
        description="Take a deep-dive into our latest research projects, publications, and findings."
        location={location}
        type="website"
      />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          zIndex: 2,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box
        component="img"
        src={reports}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: "-140px",
            top: "-360px",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            pb: 15,
          }}
        >
          <Box
            component="div"
            sx={{
              mt: { xs: 12, md: 20 },
              mb: { xs: 6, md: 12 },
            }}
          >
            <Typography sx={{ mb: 1.6 }} variant="h1">
              Research
            </Typography>
            <Typography sx={{ maxWidth: 440 }}>
              Take a deep-dive into our latest research projects, publications,
              and findings.
            </Typography>
            <Button
              to="/writing/research/"
              component={RouterLink}
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 4 }}
            >
              .Explore the Research Blog
            </Button>
          </Box>

          <Typography
            variant="h3"
            sx={{
              display: "inline-block",
              background: "#091A3D",
              border: "1px solid #4B608D",
              px: { xs: 3, md: 6 },
              py: 3,
              position: "relative",
              mb: 5,
              "&:before": {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "#091A3D",
                border: "1px solid #4B608D",
                right: "-10px",
                top: "-10px",
                zIndex: -1,
              },
            }}
          >
            Publications
          </Typography>

          {researchData.map((item, i) => (
            <Box component="div" key={i}>
              <Box
                component="div"
                sx={{ display: { md: "flex" }, gap: "10px", mb: 4 }}
              >
                <Link
                  component={RouterLink}
                  to={`/research/${slugify(item.name)}`}
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: { xs: "100%", md: "30%" },
                    background: "#091A3D",
                    border: "solid #4B608D",
                    borderWidth: { xs: "1px 1px 0 1px ", md: "1px" },
                    p: { xs: 2, md: 1.5 },
                    pt: { xs: 3, md: 1.5 },
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    src={item.cover}
                    alt={item.name}
                    sx={{
                      display: "block",
                      width: "100%",
                      maxWidth: { xs: 300, md: "intial" },
                    }}
                  />

                  <Box
                    component="div"
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(6, 22, 55, 0.7)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: 0,
                      transition: ".3s ease-in-out",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Read2 />
                  </Box>
                </Link>
                <Box
                  component="div"
                  sx={{
                    width: { xs: "100%", md: "calc(70% - 10px)" },
                    background: "#091A3D",
                    border: "solid #4B608D",
                    borderWidth: { xs: " 0 1px 1px  1px ", md: "1px" },
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    px: { xs: 2, md: 7 },
                    pt: { xs: 2, md: 9 },
                    pb: 4,
                  }}
                >
                  <Box component="div" sx={{ flex: 1, mb: 5 }}>
                    <Link
                      component={RouterLink}
                      to={`/research/${slugify(item.name)}`}
                    >
                      <Typography variant="h4" sx={{ mb: 2.4 }}>
                        {item.name}
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        color: "#1CE7C2",
                        fontSize: 12,
                        mb: 2.4,
                        "& span:not(:last-of-type):after": { content: "', '" },
                      }}
                    >
                      {item.authors &&
                        item.authors.map((a, i) => (
                          <Box component="span" key={i}>
                            {a.url ? (
                              <Link
                                href={a.url}
                                target="_blank"
                                sx={{
                                  textDecoration: "underline",
                                  color: "#1CE7C2",
                                }}
                              >
                                {a.name}
                              </Link>
                            ) : (
                              <Box component="span">{a.name}</Box>
                            )}
                          </Box>
                        ))}
                    </Typography>
                    <Typography> {item.description}</Typography>
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: "space-between",
                      gap: 2.4,
                      "& .MuiButton-root svg": { fill: "none" },
                    }}
                  >
                    <Button
                      component={OutboundLink}
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      startIcon={<Read />}
                    >
                      Read
                    </Button>

                    <Share
                      url={`https://jumpcrypto.com/research/${slugify(
                        item.name
                      )}`}
                      title={item.name}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default research;
